import React from 'react';
import Layout from '../../components/layout';
import InfosTitle from '../../components/InfosPages/infosTitle';
import ReturnLink from '../../components/InfosPages/ReturnLink';

const infos = require('../../MentionsLegal.json');
const gray = '#FCFCFC';

const LegalMentions = () => {
  // console.log(infos);
  return (
    <Layout>
      <ReturnLink />
      <div className="MainWrapper / -lg:px-16 flex flex-col items-start justify-start max-w-full px-3 pb-48 mt-6">
        <h1 className="my-5 text-2xl font-medium text-green-700">Mentions légales</h1>
        <hr className="background-green-700 w-full h-2" />
        <div className="flex flex-col items-start justify-between max-w-full px-3">
          <div
            className="-lg:w-2/3 pb-9 flex flex-col max-w-full px-3 pt-5 my-4"
            style={{ background: gray }}
          >
            {infos.map((info) => (
              <InfosTitle title={info.title} text={info.text} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LegalMentions;
